import axios from 'axios'
const HEADER_TOKEN = 'Authorization'
const METHOD_POST = 'POST'
const METHOD_GET = 'GET'

// 创建一个axios实例
const service = axios.create({
  // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API,
  // send cookies when cross-domain requests
  withCredentials: true,
  // request timeout
  timeout: 30000
})

/**
 * request拦截器
 */
service.interceptors.request.use(
  config => {
    const method = config.method
    if (method.toUpperCase() === METHOD_POST) {
      // 检查是否有token
      const token = sessionStorage.getItem('ACCESS_TOKEN')
      if (token) {
        config.headers[HEADER_TOKEN] = token
      }
    } else if (method.toUpperCase() === METHOD_GET) {
      console.log('get请求')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * response拦截器
 * * 如果您想要获取http信息，如头信息或状态信息
 * Please return  response => response
 * 通过自定义代码确定请求状态
 */
service.interceptors.response.use(
  response => {
    const res = response.data
    // res有code属性，属于api接口返回的数据
    if ('code' in res) {
      // 有code，并!=0
      if (res.code !== 0) {
        if (res instanceof Blob) {
          if (res.type !== 'application/json' && res.size !== 0) {
            return res
          } else {
            return ''
          }
        }
        return res
      } else {
        // 有code，并==0
        return res
      }
    } else {
      // res没有code属性，属于get返回的直接数据
      return res
    }
  },
  error => {
    console.error(error.message)
    return Promise.reject(error)
  }
)

export default service
