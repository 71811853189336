import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import alert from './components/alert'
// 工具类
import * as utils from '@/utils/index'
// httpApi
import * as httpApi from '@/http/httpApi'

Vue.prototype.$http = httpApi
Vue.prototype.$utils = utils
Vue.config.productionTip = false
// 弹框及消息通知组件
Vue.use(alert)
// 这里也可以直接执行 toastRegistry()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
