<template>
  <div>
    <!--路由内容-->
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      classOption: {
        limitMoveNum: 2,
        direction: 2
      }
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>

.warp {
  margin: 0 auto;
  color: #d6b872;
  overflow: hidden;
  height: 32px;
  line-height: 32px;
  width: 900px;
  flex: 1;
}

.warp ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.warp ul.ul-item {
  display: flex;
}

.warp ul.ul-item .li-item {
  width: 1900px;
  height: 32px;
  margin-right: 10px;
  line-height: 32px;
  font-size: 14px;
}
</style>
