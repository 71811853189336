import _ from 'lodash'

/**
 *判断是否是数字
 *
 **/
export function isRealNum(val) {
  // isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
  if (val === '' || val == null) {
    return false
  }
  if (!isNaN(val)) {
    // 对于空数组和只有一个数值成员的数组或全是数字组成的字符串，isNaN返回false，例如：'123'、[]、[2]、['123'],isNaN返回false,
    // 所以如果不需要val包含这些特殊情况，则这个判断改写为if(!isNaN(val) && typeof val === 'number' )
    return true
  } else {
    return false
  }
}

/**
 * 获取当前时间戳:毫秒
 * @returns {number}
 */
export function getTimeStampMSec() {
  return new Date().getTime()
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
}

/**
 *
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()
  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
    )
  }
}

/**
 * 时间格式化
 *
 * @param {number} time 字符串
 * @param {string} option
 * @returns {string}
 */
export function formatTimeStr(time) {
  if (!time) {
    return ''
  }
  let res
  let date
  if (time.constructor.name === 'Date') {
    date = time
  } else {
    date = new Date(time)
  }
  // const date = new Date(time)
  if (date) {
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hour = date.getHours().toString().padStart(2, '0')
    const minute = date.getMinutes().toString().padStart(2, '0')
    const second = date.getSeconds().toString().padStart(2, '0')
    res = date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
  } else {
    res = ''
  }
  if (res.indexOf('1900') >= 0) {
    res = ''
  }
  return res
}

/**
 * 时间格式化
 *
 * @param {number} time 字符串
 * @param {string} option
 * @returns {string}
 */
export function formatTimeToDay(time) {
  if (!time) {
    return ''
  }
  let res
  let date
  if (time.constructor.name === 'Date') {
    date = time
  } else {
    date = new Date(time)
  }
  // const date = new Date(time)
  if (date) {
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    res = date.getFullYear() + '年' + month + '月' + day + '日'
  } else {
    res = ''
  }
  if (res.indexOf('1900') >= 0) {
    res = ''
  }
  return res
}

/**
 * 生成时间戳字符串
 * @returns {string}
 */
export function getNowTimeStr() {
  const date = new Date()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')
  const second = date.getSeconds().toString().padStart(2, '0')
  return date.getFullYear() + '' + month + '' + day + hour + '' + minute + '' + second
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (let i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) {
      s++
    } else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {string}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * 简单版深拷贝
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('deepClone - error arguments')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * lodash的深拷贝
 * @param source 源
 * @returns {*} 结果
 */
export function cloneDeep(source) {
  return _.cloneDeep(source)
}

/**
 * 数组去重
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * 创建唯一字符串
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * 检查元素是否有 此 class
 * @param {HTMLElement} ele
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} ele
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} ele
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

/** *
 * 对field对应的字典数组，通过code==>value
 * @param dictObject 当前字典数组 ： [{field,code,value},{field,code,value}]
 * @param code 代码值
 * @returns value 代码含义
 */
export function getDictObjectValueByCode(dictObject, code) {
  let value = ''
  dictObject.some((dict) => {
    if (code === dict.code) {
      value = dict.value
      return true
    }
    return false
  })
  return value
}

/**
 * 表单重置
 * @param refName 表单名
 * @param context 上下文
 */
export function resetForm(refName, context) {
  if (context.$refs[refName]) {
    context.$refs[refName].resetFields()
  }
}

/**
 * 添加日期范围
 * @param queryParams 表单参数
 * @param dateRange 日期组件 日期范围数组 []
 * @param propName 属性后缀
 * @returns {*}
 */
export function addDateRange(queryParams, dateRange, propName) {
  const search = queryParams
  search.params = {}
  if (dateRange != null && dateRange !== '') {
    if (typeof (propName) === 'undefined') {
      search.params.beginTime = dateRange[0]
      search.params.endTime = dateRange[1]
    } else {
      search.params['begin' + propName] = dateRange[0]
      search.params['end' + propName] = dateRange[1]
    }
  }
  return search
}

/**
 * 判断对象类型
 * @param value
 * @returns {array|date|boolean|number|function|regexp|object}
 */
export function getObjectType(value) {
  const oP = Object.prototype
  const toString = oP.toString
  if (value === null) {
    return 'null'
  }
  const type = typeof value
  if (type === 'undefined' || type === 'string') {
    return type
  }
  const typeString = toString.call(value)
  switch (typeString) {
    case '[object Array]':
      return 'array'
    case '[object Date]':
      return 'date'
    case '[object Boolean]':
      return 'boolean'
    case '[object Number]':
      return 'number'
    case '[object Function]':
      return 'function'
    case '[object RegExp]':
      return 'regexp'
    case '[object Object]':
      if (undefined !== value.nodeType) {
        if (value.nodeType === 3) {
          return (/\S/).test(value.nodeValue) ? 'textnode' : 'whitespace'
        } else {
          return 'element'
        }
      } else {
        return 'object'
      }
    default:
      return 'unknow'
  }
}

/** *
 * 生成计算表达式
 */
export function generateExp() {
  let result
  let exp
  let num1 = Math.floor(Math.random() * 10)
  let num2 = Math.floor(Math.random() * 10)
  // 随机算法
  const tmpArith = Math.floor(Math.random() * 3)
  switch (tmpArith) {
    case 1:
      result = num1 + num2
      exp = num1 + ' + ' + num2 + ' = ?'
      break
    case 2:
      if (parseInt(num1.toString()) < parseInt(num2.toString())) {
        const tmpNum = num1
        num1 = num2
        num2 = tmpNum
      }
      result = num1 - num2
      exp = num1 + ' - ' + num2 + ' = ?'
      break
    default:
      result = num1 * num2
      exp = num1 + ' × ' + num2 + ' = ?'
      break
  }
  return { exp: exp, result: result }
}

/** 检测对象中是否有某个属性或方法 **/
export function isHostMethod(object, property) {
  const t = typeof object[property]
  return t === 'function' || (!!(t === 'object' && object[property])) || t === 'unknown'
}

/** 对字符串集合随机排列，随机输出指定的长度 **/
export function randomString(length) {
  const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = length; i > 0; --i) {
    result += str[Math.floor(Math.random() * str.length)]
  }
  return result
}

/** 随机输出指定范围的数 **/
export function GetRandomNum(Min, Max) {
  const Range = Max - Min
  const Rand = Math.random()
  return (Min + Math.round(Rand * Range))
}

/** 取最大**/
export function minMax(min, max) {
  if (max > min) {
    return max
  }
  return min
}

export function myColors() {
  return [
    '#C5E384',
    '#7FDA87',
    '#20B2AA',
    '#F08080',
    '#C27FDA',
    '#E082B2',
    '#EE8E8A',
    '#EEB48A',
    '#EEC88A',
    '#00BFFF',
    '#5AB4FC',
    '#4DFFFF'
  ]
}

/**
 * 对象是否为空
 * @param obj
 * @returns {boolean}
 */
export function objectIsEmpty(obj) {
  if (!obj) {
    return true
  }
  return Object.keys(obj).length === 0
}

