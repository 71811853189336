import request from '@/utils/request'
import axios from 'axios'

/**
 * get获取本地json
 * @param method 路径
 * @returns {Promise<unknown>}
 */
export function getLocalJson(method) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: method,
      dataType: 'json',
      crossDomain: true,
      cache: false
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

/**
 * get获取通行令牌
 * @param data 路径
 * @returns {Promise<unknown>}
 */
export function getAccessToken(data) {
  return new Promise((resolve, reject) => {
    request({
      url: '/deviceApi/token?boxId=' + data.username + '&password=' + data.password + '&uaType=6',
      method: 'get'
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * get请求
 * @param url 路径
 * @returns {Promise<unknown>}
 */
export function httpGet(url) {
  return new Promise((resolve, reject) => {
    request({
      url: url,
      method: 'get'
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * post请求指令服务
 * @param params 参数对象
 * @param responseType 响应类型
 * @returns {Promise<unknown>} json返回值
 */
export function httpPost(params, responseType = 'json') {
  return new Promise((resolve, reject) => {
    request({
      url: '/api/service',
      method: 'post',
      responseType: responseType,
      data: params || {}
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * post请求指令服务
 * @param params 参数对象
 * @param responseType 响应类型
 * @returns {Promise<unknown>} json返回值
 */
export function httpAdminPost(params, responseType = 'json') {
  return new Promise((resolve, reject) => {
    request({
      url: '/api/service',
      method: 'post',
      responseType: responseType,
      data: params || {}
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * post请求指令服务
 * @param params 参数对象
 * @returns {Promise<unknown>} Blob返回值
 */
export function httpPostDownload(params) {
  return new Promise((resolve, reject) => {
    request({
      url: '/download',
      method: 'post',
      responseType: 'blob',
      data: params || {}
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export function postWithPath(params, path) {
  return new Promise((resolve, reject) => {
    request({
      url: path,
      method: 'post',
      data: params || {}
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

