import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/IndexView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index')
  },
  {
    path: '/registerRouter',
    name: 'RegisterRouter',
    component: () => import('../views/login/registerRouter.vue')
  },
  {
    path: '/errorView',
    name: 'ErrorView',
    component: () => import('../views/ErrorView.vue')
  },
  {
    path: '/borrowView',
    name: 'BorrowView',
    component: () => import('../views/BorrowView.vue')
  },
  {
    path: '/borrowView2',
    name: 'BorrowView',
    component: () => import('../views/BorrowView2.vue')
  },
  {
    path: '/loginView',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/boxBind',
    name: 'BoxBindView',
    component: () => import('../views/BoxBindView.vue')
  },
  {
    path: '/borrowListView',
    name: 'BorrowListView',
    component: () => import('../views/lbQueryDevice/BorrowListView.vue')
  },
  {
    path: '/bookStockAreaView',
    name: 'BookStockAreaView',
    component: () => import('../views/lbQueryDevice/BookStockAreaView.vue')
  },
  {
    path: '/growSmartIndex',
    name: 'GrowSmartIndex',
    component: () => import('../views/growSmart/IndexView.vue')
  },
  {
    path: '/growSmartAnswer',
    name: 'GrowSmartAnswer',
    component: () => import('../views/growSmart/AnswerView.vue')
  },
  {
    path: '/growSmartGpt',
    name: 'GrowSmartGpt',
    component: () => import('../views/growSmart/SmartCpt.vue')
  },
  {
    path: '/bookDetailView',
    name: 'BookDetailView',
    component: () => import('../views/lbQueryDevice/BookDetailView.vue')
  }
]

const router = new VueRouter({
  routes
})

// 保存下来push方法 将来我们还会用到原push方法进行路由跳转
const originPush = VueRouter.prototype.push
// 接下来我们重写push|replace方法
// 第一个参数location参数像原push方法声明跳转的地方 resolve和reject传递成功与失败
VueRouter.prototype.push = function(location, resolve, reject) {
  if (resolve && reject) {
    // 如果成功 调用原来的push方法
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(this, location, () => {}, () => {})
  }
}
export default router
